import {
  FormControl as MuiFormControl,
  FormHelperText,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

import { FormControlLabel } from '../FormControlLabel';
import { Radio } from '../Radio';
import { Typography } from '../Typography';
import { withMuiError } from '../WithMuiError';
import { useStyles } from './styles';

interface Option {
  label: React.ReactNode;
  value: string | boolean;
  default?: boolean;
}

type MuiProps = React.ComponentProps<typeof MuiRadioGroup>;
interface RadioGroupFieldProps extends MuiProps {
  options?: Option[];
  required: boolean;
  defaultValue?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  name: string;
  onInvalid?: React.FormEventHandler<HTMLElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const RadioGroupField: React.VFC<RadioGroupFieldProps> = ({
  options,
  required,
  defaultValue,
  error,
  helperText,
  name,
  onInvalid,
  ...rest
}) => {
  const styles = useStyles();
  return (
    <MuiFormControl
      component="fieldset"
      onInvalid={onInvalid}
      error={error}
      fullWidth
    >
      <MuiRadioGroup
        defaultValue={defaultValue}
        className={cx(styles.formGroupRoot, { [styles.withError]: error })}
        {...rest}
      >
        {(options ?? []).map(option => (
          <FormControlLabel
            key={option.value.toString()}
            value={option.value.toString()} // on some occasions the Ruby back end sends us something that isn't a string, this is insurance
            control={<Radio name={name} required={required} size="small" />}
            label={option.label}
          />
        ))}
      </MuiRadioGroup>
      <FormHelperText component={Typography}>
        {error && helperText}
      </FormHelperText>
    </MuiFormControl>
  );
};

const ValidatedRadioGroupField = withMuiError(RadioGroupField);
ValidatedRadioGroupField.displayName = 'RadioGroupField';

export { ValidatedRadioGroupField as RadioGroupField };

type ValidatedRadioGroupFieldProps = React.ComponentProps<
  typeof ValidatedRadioGroupField
>;
export type { ValidatedRadioGroupFieldProps as RadioGroupFieldProps };
