import React from 'react';

import { TextField } from '../TextField';

type MuiProps = Omit<React.ComponentProps<typeof TextField>, 'type'>;
export interface DatepickerProps extends MuiProps {
  id: string;
  missingValueText?: React.ReactNode;
}

export const Datepicker: React.VFC<DatepickerProps> = ({ id, ...rest }) => {
  return (
    <TextField
      {...rest}
      id={id}
      type="date"
      inputProps={{
        min: `${new Date().getFullYear() - 120}-01-01`,
        max: `${new Date().getFullYear() + 120}-01-01`,
      }}
    />
  );
};
